import React, { useMemo, useState } from 'react'
import { useStore } from 'store'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'

import { LOAN_STATUS_ID_MAPPING } from 'modules/loan-applications/constants'
import { ACH_STATUS, STATES_ORDER } from 'modules/loan-application/constants'
import StatusComponent from 'components/status'

import { formatIntToCurrency } from 'modules/loan-application/utils'
import ChangeAmountModal from 'modules/loan-application/select-loan/loan-blocked-modal/change-amount-modal'

import styles from './style.module.scss'
import ChangeLoanProductModal from './change-loan-product'

const { Dialog } = components

const LoanSummaryModal = ({
  loanData,
  setIsModalOpen,
  achStatus,
  dispatch,
}) => {
  const { t: translate } = useTranslation()

  const [isChangeAmountOpen, setIsChangeAmountOpen] = useState(false)
  const [isChangeLoanProductOpen, setIsChangeLoanProductOpen] = useState(false)
  const { state } = useStore()

  const { currentState } = state.flow

  const currentStateOrder = useMemo(() => {
    return STATES_ORDER[currentState.name]
  }, [currentState])

  const ntpFundsRequested = currentStateOrder >= STATES_ORDER.NTPFundsRequested

  const achStatusText =
    achStatus !== null && achStatus !== undefined
      ? translate(`loanApplication.statusCard.achStatus.status.${achStatus}`)
      : translate(
          `loanApplication.statusCard.achStatus.status.${ACH_STATUS.INCOMPLETE}`
        )

  return (
    <>
      <Dialog
        open={true}
        onClose={() => setIsModalOpen(false)}
        title="General Loan Information"
        PaperProps={{
          sx: {
            maxWidth: '100%',
            width: '700px',
          },
        }}
        titleProps={{
          sx: {
            paddingBottom: '24px',
          },
        }}
      >
        <div>
          <div className={styles.generalCard}>
            <div>
              <div className={styles.label}>
                {translate('loanApplication.statusCard.summaryModal.status')}
              </div>
              <StatusComponent
                status={LOAN_STATUS_ID_MAPPING[loanData.loanStatus?.id]}
                label={translate(
                  `loanApplication.statusCard.loanApplicationStatus.${loanData.loanStatus?.id}`
                )}
              />
            </div>
            <div>
              <div className={styles.label}>
                {translate(
                  'loanApplication.statusCard.summaryModal.applicationNumber'
                )}
              </div>
              {loanData.loanApplicationNumber}
            </div>
            <div>
              <div className={styles.label}>
                {translate('loanApplication.statusCard.summaryModal.autopay')}
              </div>
              {achStatusText}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ disply: 'flex', flexDirection: 'column' }}>
                <div className={styles.label}>
                  {translate(
                    'loanApplication.statusCard.summaryModal.loanAmount'
                  )}
                </div>
                <span style={{ color: 'black' }}>
                  {formatIntToCurrency(loanData.loanAmount)}
                </span>
              </div>
              {!ntpFundsRequested && (
                <div
                  className="loan-blocked__link"
                  onClick={() => setIsChangeAmountOpen(true)}
                >
                  Change
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className={styles.label}>
                  {translate(
                    'loanApplication.statusCard.summaryModal.loanProduct'
                  )}
                </div>
                <span style={{ color: 'black' }}>{loanData.loanName}</span>
              </div>
              {!ntpFundsRequested && (
                <div
                  className="loan-blocked__link"
                  onClick={() => setIsChangeLoanProductOpen(true)}
                >
                  Change
                </div>
              )}
            </div>
          </div>
        </div>
      </Dialog>
      <ChangeAmountModal
        dispatch={dispatch}
        loanApplicationId={loanData.loanApplicationId}
        loanApplicationStateId={loanData.loanApplicationState.id}
        isOpen={isChangeAmountOpen}
        maxAmount={null}
        onClose={() => {
          setIsChangeAmountOpen(false)
        }}
        handleGetSelfServiceCriterias={() => {}}
      />
      <ChangeLoanProductModal
        dispatch={dispatch}
        loanApplicationId={loanData.loanApplicationId}
        isOpen={isChangeLoanProductOpen}
        maxAmount={null}
        loanName={loanData.loanName}
        onClose={() => {
          setIsChangeLoanProductOpen(false)
        }}
        setModalOpen={setIsChangeLoanProductOpen}
        handleGetSelfServiceCriterias={() => {}}
      />
    </>
  )
}

LoanSummaryModal.propTypes = {
  loanData: PropTypes.object.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  achStatus: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
}

export default LoanSummaryModal
